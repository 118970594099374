import { AiOutlineWhatsApp } from 'react-icons/ai';

export default function BtnWhatsapp({ phone }) {
  return (
    <a
      className='btn-whatsapp d-block'
      href={`https://wa.me/${phone?.replace(/ +/g, '')}`}
      target={'_blank'}
      rel='noreferrer'
    >
      <AiOutlineWhatsApp />
    </a>
  );
}
