import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
  en: {
    translation: {
      home: 'Home',
      'about-center': 'About The Center',
      courses: 'Courses',
      'virtual-library': 'Virtual Library',
      'data-center-rental': 'Data Center Rental',
      blogs: 'Blogs',
      books: 'Books',
      sdg: 'SDG',
      'join-course': 'Join This Course',
      hours: 'Hours',
      'upcoming-courses': 'Upcoming Courses',
      'virtual-courses': 'Virtual Courses',
      'all-courses': 'All Courses',
      'course-content': 'Course Content',
      'books-files': 'Books And Files',
      download: 'Download',
      details: 'Details',
      'intro-video': 'Intro Video',
      'first-video': 'First Video',
      register: ' Register',
      'about-course': 'About This Course',
      'register-now': ' Register Now',
      'contact-us': 'Contact Us',
      'quick-links': 'Quick Links',
      'about-network-plus': 'About Network Plus Training Center',
      'network-plus': 'Network Plus Training Center',
      'our-partners': 'Our Partners',
      'get-in-touch': 'Get In Touch',
      'full-name': 'Full Name',
      'email-address': 'Email Address',
      message: 'Message',
      send: 'Send',
      'error-message-register':
        'An error occurred, the registration was not completed, please try again',
      'error-message': 'Comment not sent, please try again!',
      'invalid-email-format': 'Invalid email format',
      'name-required': 'full name required',
      'name-less-three-characters':
        'The name you should not be less than 3 characters',
      'name-must-than-twenty-characters':
        'The name must not than 75 characters',
      'email-required': 'email required',
      'email-less-three-characters':
        'The email you should not be less than 3 characters',
      'email-must-than-twenty-characters':
        'The email must not than 75 characters',
      'phone-required': 'phone number required',
      'phone-less-thirteen-digit':
        "Phone Number Shouldn't Be Less than 13 Digits",
      'phone-must-than-thirteen-digit':
        'The phone number you must not than 13 digit',
      'message-required': 'message required',
      'message-less-three-characters':
        'The message you should not be less than 3 characters',
      'message-must-than-five-hundred-characters':
        'The message must not than 500 characters',
      'success-message-contact':
        'Thank you for your interest. Your message has been sent and our team will contact you shortly.',
    },
  },
  ar: {
    translation: {
      home: 'الرئسية',
      'about-center': 'عن المركز',
      courses: 'الكورسات',
      'virtual-library': 'المكتبة الافتراضية',
      'data-center-rental': 'إيجار مركز البيانات',
      blogs: 'التدوينات',
      books: 'الكتب',
      sdg: 'جنية سوداني',
      'join-course': 'انضم إلى هذه الدورة',
      hours: 'ساعة',
      details: 'التفاصيل',
      'intro-video': 'المقدمة',
      'first-video': 'الفديو الاول',
      'about-course': 'معلومات حول هذا الكورس',
      'course-content': 'محتوي الكورس',
      'all-courses': 'جميع الكورسات',
      'books-files': 'الكتب و الملفات',
      'upcoming-courses': 'البرامج القادمة',
      'virtual-courses': 'المكتبة المسجلة',
      download: 'تحميل',
      register: 'تسجيل الان',
      'register-now': 'تسجيل',
      'contact-us': 'تواصل معنا',
      'quick-links': 'روابط سريعة',
      'about-network-plus': 'عن مركز نت وورك بلس  للتدريب  ',
      'network-plus': 'مركز نت وورك بلس  للتدريب',
      'our-partners': 'شركائنا',
      'get-in-touch': 'تواصل معنا',
      'full-name': 'الاسم الكامل',
      'email-address': ' البريد الالكترونى',
      message: 'الرسالة',
      send: 'إرسال',
      'invalid-email-format': 'تنسيق بريد إلكتروني غير صالح',
      'name-required': ' الاسم مطلوب',
      'name-less-three-characters': 'يجب ألا يقل الاسم عن 3 أحرف',
      'name-must-twenty-characters': 'يجب ألا يزيد الاسم عن 75 حرفًا',
      'email-required': 'البريد الالكتروني مطلوب',
      'email-less-three-characters':
        'البريد التلكتروني يجب ان الا يقل عن 3 حروف',
      'email-must-than-twenty-characters':
        'البريد الالكتروني يجب الايزيد عنن 75 حرف',
      'phone-required': 'رقم الهاتف مطلوب',
      'phone-less-thirteen-digit': 'رقم الهاتف يجب الا يقل عن 13 رقم',
      'phone-must-than-thirteen-digit': 'رقم الهاتف يجي الا يزيد عن 13 رقم',
      'message-required': ' الرسالة مطلوب',
      'message-less-three-characters': 'يجب ألا تقل الرسالة عن 3 أحرف',
      'message-must-five-hundred-characters':
        'يجب ألا تزيد الرسالة عن 500 حرفًا',
      'error-message-register':
        'حدث خطا لم يتم التسجيل الرجاء المحاولة مرة اخري',
      'error-message': 'لم يتم  ارسال التعليق يرجى المحاولة مرة أخرى!',
      'success-message-contact':
        'شكرا لك على اهتمامك. تم إرسال رسالتك وسيقوم فريقنا بالتواصل بك قريبًا',
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lang: 'en',
  fallbackLng: 'en',
});

export default i18n;
