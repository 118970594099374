import { useLocation } from 'react-router';

export default function DetailsBlog({ lang }) {
  const location = useLocation();
  const blog = location?.state;
  return (
    <div className='blog-details pt-4 pb-5'>
      <div className='container'>
        <h2 className='title fw-bolder' data-aos='fade-up' data-aos-delay='100'>
          {lang === 'en' ? blog?.title_en : blog?.title_ar}
        </h2>
        <p
          className='desc'
          data-aos='fade-up'
          data-aos-delay='150'
          dangerouslySetInnerHTML={{
            __html: lang === 'en' ? blog?.description_en : blog?.description_ar,
          }}
        ></p>
        <div className='row mt-3'>
          <div className='col-md-3 mb-1'>
            <div className='image' data-aos='fade-right' data-aos-delay='150'>
              <img
                src={blog?.image}
                alt={lang === 'en' ? blog?.title_en : blog?.title_ar}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
