import { useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaWhatsapp,
  FaTelegramPlane,
  FaYoutube,
} from 'react-icons/fa';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { RxEnvelopeClosed } from 'react-icons/rx';
import { FiPhone } from 'react-icons/fi';
import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';

export default function Footer({ t, lang, setPhone }) {
  const { data, isError, isLoading, error } = useFetchData(
    'informations',
    'informations'
  );

  useEffect(() => {
    data?.data?.data?.phone && setPhone(data?.data?.data?.phone);
  }, [data?.data?.data?.phone, setPhone]);

  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;

  return (
    <div className='footer py-5 pb-3'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4 mb-3 mb-md-0'>
            <div className='image'>
              <img src='../../assets/images/img-logo.png' alt='img-logo' />
            </div>
            <ul className='social-list list-unstyled d-flex align-items-center gap-3 mt-3 mt-md-4'>
              {data?.data?.data?.social_media?.whatsapp && (
                <li>
                  <a href={data?.data?.data?.social_media?.whatsapp}>
                    <FaWhatsapp size='1.5rem' />
                  </a>
                </li>
              )}
              {data?.data?.data?.social_media?.facebook && (
                <li>
                  <a href={data?.data?.data?.social_media?.facebook}>
                    <FaFacebookF size='1.5rem' />
                  </a>
                </li>
              )}
              {data?.data?.data?.social_media?.telegram && (
                <li>
                  <a href={data?.data?.data?.social_media?.telegram}>
                    <FaTelegramPlane size='1.5rem' />
                  </a>
                </li>
              )}
              {data?.data?.data?.social_media?.linkedin && (
                <li>
                  <a href={data?.data?.data?.social_media?.linkedin}>
                    <FaLinkedinIn size='1.5rem' />
                  </a>
                </li>
              )}
              {data?.data?.data?.social_media?.youtube && (
                <li>
                  <a href={data?.data?.data?.social_media?.youtube}>
                    <FaYoutube size='1.5rem' />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className='col-md-4 mb-4 mb-md-0'>
            <h3 className='title'>{t('quick-links')}</h3>
            <ul className='list-item list-unstyled'>
              <li>
                <Link to='/about-the-center'>{t('about-center')}</Link>
              </li>
              <li>
                <Link to='/#courses'>{t('courses')}</Link>
              </li>
              <li>
                <Link to='/blogs'>{t('blogs')}</Link>
              </li>
              <li>
                <Link to='/books'>{t('books')}</Link>
              </li>
              <li>
                <Link to='/data-center-rental'>{t('data-center-rental')}</Link>
              </li>
            </ul>
          </div>
          <div className='col-md-4 mb-4 mb-md-0'>
            <h3 className='title'>{t('contact-us')}</h3>
            <ul className='list-item list-unstyled'>
              <li>
                <a href={`tel:${data?.data?.data?.phone}`}>
                  <FiPhone color='#3B3B3B' />
                  {data?.data?.data?.phone}
                </a>
              </li>
              <li>
                <a href={`mailto:${data?.data?.data?.email}`}>
                  <RxEnvelopeClosed color='#3B3B3B' />
                  {data?.data?.data?.email}
                </a>
              </li>
              <li>
                <a href='/'>
                  <HiOutlineLocationMarker color='#3B3B3B' />
                  {lang === 'en'
                    ? data?.data?.data?.address_en
                    : data?.data?.data?.address_ar}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
