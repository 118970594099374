import { Link } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function CoursesSlider({ t, lang }) {
  return (
    <div className='all-courses text-center pt-5'>
      <h2
        className='section-title fw-bolder text-uppercase text-white mb-5'
        data-aos='fade-up'
        data-aos-delay='100'
      >
        {t('all-courses')}
      </h2>
      <Link
        className='btn-all b-primary text-white rounded-2 mt-4 mb-5 text-decoration-none'
        to='/courses'
        data-aos='fade-up'
        data-aos-delay='200'
      >
        {t('all-courses')}
        {lang === 'en' ? <MdKeyboardArrowRight /> : <MdKeyboardArrowLeft />}
      </Link>
      <Swiper
        className='multi-swiper pt-5 mt-5'
        data-aos='fade-left'
        data-aos-delay='100'
        centeredSlides={true}
        spaceBetween={20}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 10,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 10,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-1.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-2.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-3.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-4.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-5.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-6.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-7.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-8.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-9.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            loading='lazy'
            src='../assets/images/courses/img-10.png'
            alt='img-courses'
            data-aos='fade-up'
            data-aos-delay='100'
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
