import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';
import { AiOutlineMenu } from 'react-icons/ai';
import { MdLanguage } from 'react-icons/md';
import checkTypeLanguage from '../../utils/checkTypeLanguage';
import closeNavbarMenuMobile from '../../utils/closeNavbarMenuMobile';
import handleHideAndShowBtnScrollTop from '../../utils/handleHideAndShowBtnScrollTop';
import changeLanguage from '../../utils/changeLanguage';
import DropdownCourses from './DropdownCourses';
import controlsDropdownMenuNav from '../../utils/controlsDropdownMenuNav';

export default function Navbar({ lang }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [tvLang, setTvLang] = useState('عربي');
  // ----------------------------------------------------------------------------->
  // effect render after change language
  const renderChangeLang = useCallback(() => {
    if (checkTypeLanguage() === 'en') {
      document.querySelector('body').style.direction = 'ltr';
      document.querySelector('html').setAttribute('lang', 'en');
      document.querySelector('html').setAttribute('dir', 'ltr');
      i18n.changeLanguage('en');
      setTvLang('عربي');
    } else if (checkTypeLanguage() === 'ar') {
      document.querySelector('body').style.direction = 'rtl';
      document.querySelector('html').setAttribute('lang', 'ar');
      document.querySelector('html').setAttribute('dir', 'rtl');
      i18n.changeLanguage('ar');
      setTvLang('English');
    }
  }, [i18n]);
  // ----------------------------------------------------------------------------->
  useEffect(() => {
    renderChangeLang();
  }, [renderChangeLang]);
  // ----------------------------------------------------------------------------->
  useEffect(() => {
    closeNavbarMenuMobile();
  }, []);
  // ----------------------------------------------------------------------------->
  useEffect(() => {
    document.addEventListener('scroll', handleHideAndShowBtnScrollTop);
  }, []);
  useEffect(() => {
    controlsDropdownMenuNav();
  });

  return (
    <nav className='navbar navbar-expand-lg'>
      <div className='container align-items-center'>
        <Link className='navbar-brand' to='/'>
          <img src='../../assets/images/img-logo.png' alt='img-logo' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <AiOutlineMenu />
        </button>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav gap-2'>
            <li className='nav-item active'>
              <Link className='nav-link' aria-current='page' to='/'>
                {t('home')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/about-the-center'>
                {t('about-center')}
              </Link>
            </li>
            <li className='nav-item dropdown' id='coursesDropdown'>
              <Link
                className='nav-link dropdown-toggle'
                href='#'
                data-bs-toggle='dropdown'
              >
                {t('courses')}
              </Link>
              <DropdownCourses lang={lang} type='courses' />
            </li>
            <li className='nav-item dropdown' id='virtualLibraryDropdown'>
              <Link
                className='nav-link dropdown-toggle'
                href='#'
                data-bs-toggle='dropdown'
              >
                {t('virtual-library')}
              </Link>
              <DropdownCourses lang={lang} type='virtual_courses' />
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/data-center-rental'>
                {t('data-center-rental')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/blogs'>
                {t('blogs')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/books'>
                {t('books')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link'
                to='/#contact-us'
                data-value='contact-us'
              >
                {t('contact-us')}
              </Link>
            </li>
            <li className='nav-item'>
              <span
                className='nav-link rounded-pill px-4'
                onClick={() => changeLanguage(i18n, navigate)}
              >
                {tvLang} <MdLanguage />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
