import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';
import Blog from './Blog';

export default function Blogs({ t, lang }) {
  const {
    data: blogs,
    isError,
    isLoading,
    error,
  } = useFetchData('articles', 'articles');
  // check is error
  if (isError) return <div className='text-error text-center'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <div className='blogs pb-5 pt-4'>
      <div className='container'>
        <h2
          className='section-title fw-bolder text-md-center'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {t('blogs')}
        </h2>
        <div className='row mt-md-5 mt-4'>
          {blogs?.data?.data?.map((blog) => (
            <Blog lang={lang} blog={blog} />
          ))}
        </div>
      </div>
    </div>
  );
}
