import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import LoadingSection from '../../helpers/loading-section/LoadingSection';
import useFetchData from '../../api/ApiData';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function Partners({ t }) {
  const { data, isError, isLoading, error } = useFetchData(
    'partners',
    'partners'
  );
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;

  return (
    <div className='partners py-5' id='partners'>
      <div className='container'>
        <h2
          className='text-center text-uppercase fw-bold'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {t('our-partners')}
        </h2>
        {data?.data?.data && (
          <Swiper
            className='multi-swiper mt-5'
            data-aos='fade-left'
            data-aos-delay='100'
            centeredSlides={true}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 7,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 7,
                spaceBetween: 50,
              },
            }}
          >
            {data?.data?.data?.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  height={120}
                  width={120}
                  loading='lazy'
                  src={image}
                  alt='img-partners'
                  data-aos='fade-up'
                  data-aos-delay='100'
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
