import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';

export default function Videos() {
  const {
    data: videos,
    isError,
    isLoading,
    error,
  } = useFetchData('videos', 'videos');
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <div className='videos py-5'>
      <div className='container'>
        <div className='row'>
          {videos?.data?.data?.map((video, index) => (
            <div
              key={index}
              className='col-md-6 mb-4'
              data-aos='fade-right'
              data-aos-delay='100'
            >
              <div className='video'>
                <iframe
                  width='100%'
                  height='240'
                  src={video}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
