import { Link } from 'react-router-dom';
import { FaAngleDoubleRight } from 'react-icons/fa';
import useFetchData from '../../api/ApiData';

export default function DropdownCourses({ lang, type }) {
  const { data } = useFetchData(
    'categories-with-courses',
    'categories-with-courses'
  );

  return (
    <ul className='dropdown-menu'>
      {data?.data?.data?.map((items) => (
        <li className='dropdown-item' key={items?.slug}>
          <a className='dropdown-item' href='#/'>
            {lang === 'en' ? items?.title_en : items?.title_ar}{' '}
            {items?.[type].length > 0 && <FaAngleDoubleRight />}
          </a>
          {items?.[type].length > 0 && (
            <ul className='submenu dropdown-menu'>
              <li className='dropdown-item'>
                {items?.[type]?.map((item) => (
                  <Link
                    key={item?.slug}
                    className='dropdown-item'
                    to={`/courses/${type === 'virtual_courses' && 'virtual/'}${
                      item?.slug
                    }`}
                  >
                    <span className='sub'>
                      {lang === 'en' ? item?.title_en : item?.title_ar}
                    </span>
                  </Link>
                ))}
              </li>
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}
