export default function Book({ t, lang, book }) {
  return (
    <div key={book?.id} className='col-lg-3 col-md-6 h-100' data-aos='fade-right' data-aos-delay='150'>
      <div className='box'>
        <div className='image'>
          <img
            src={book?.image}
            alt={lang === 'en' ? book?.title_en : book?.title_ar}
          />
        </div>
        <div className='info'>
          <h3 className='name fw-bolder' data-aos='fade-up' data-aos-delay='100'>
            {lang === 'en' ? book?.title_en : book?.title_ar}
          </h3>
          <p className='desc' data-aos='fade-up' data-aos-delay='150'>
            {lang === 'en' ? book?.description_en : book?.description_ar}
          </p>
          <a
            href={book?.file}
            download
            className='b-secondary rounded-3 text-decoration-none'
            data-aos='fade-up'
            data-aos-delay='200'
          >
            {t('download')}
          </a>
        </div>
      </div>
    </div>
  );
}
