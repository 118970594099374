import ContactUs from '../../components/contact-us/ContactUs';
import Upcoming from '../../components/course/Upcoming';
import Virtual from '../../components/course/Virtual';
import CoursesSlider from '../../components/courses-slider/CoursesSlider';
import Hero from '../../components/hero/Hero';
import Partners from '../../components/partner/Partners';
import Videos from '../../components/video/Videos';

export default function Home({ t, lang }) {
  return (
    <>
      <Hero t={t} lang={lang} />
      <Upcoming t={t} lang={lang} />
      <CoursesSlider t={t} lang={lang} />
      <Virtual t={t} lang={lang} />
      <Videos />
      <Partners t={t} />
      <ContactUs t={t} />
    </>
  );
}
