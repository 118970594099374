import { useNavigate } from 'react-router-dom';
import { MdDateRange } from 'react-icons/md';
import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';

export default function Courses({ t, lang }) {
  const navigate = useNavigate();
  const {
    data: courses,
    isError,
    isLoading,
    error,
  } = useFetchData('courses?type=course', 'courses');
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <div className='upcoming-courses py-5'>
      <div className='container'>
        <h2
          className='section-title fw-bolder text-center'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {t('all-courses')}
        </h2>
        <div className='row pt-4 px-3 px-md-0'>
          {courses?.data?.data?.map((course) => (
            <div
              key={course?.id}
              className='col-lg-4 col-md-6 h-100'
              data-aos='fade-right'
              data-aos-delay='150'
            >
              <div className='course'>
                <div className='image' data-aos='fade-up' data-aos-delay='150'>
                  <img
                    src={course?.image}
                    alt={lang === 'en' ? course?.title_en : course?.title_ar}
                  />
                </div>
                <div className='info p-3 pb-0'>
                  <div className='date-time'>
                    <span>
                      <MdDateRange /> {course?.start_at}
                    </span>
                  </div>
                  <h3
                    className='name fw-bolder'
                    data-aos='fade-up'
                    data-aos-delay='200'
                  >
                    {lang === 'en' ? course?.title_en : course?.title_ar}
                  </h3>
                  <p className='desc' data-aos='fade-up' data-aos-delay='250'>
                    {lang === 'en'
                      ? course?.description_en.substring(0, 40)
                      : course?.description_ar.substring(0, 40)}{' '}
                    ...
                  </p>
                  <div className='time-and-cost d-flex justify-content-between'>
                    <h4 className='time'>
                      <img
                        src='../assets/images/courses/icon-date-time.png'
                        alt='icon-date-time'
                      />{' '}
                      {course?.hours} {t('hours')}
                    </h4>
                    <h4 className='cost'>
                      <img
                        src='../assets/images/courses/icon-fees.png'
                        alt='icon-fees'
                      />{' '}
                      {course?.fees} {t('sdg')}
                    </h4>
                  </div>
                </div>
                <div
                  className='actions p-2 px-3 d-flex gap-3 text-center'
                  data-aos='fade-up'
                  data-aos-delay='300'
                >
                  <span
                    className='details rounded-3 w-100'
                    onClick={() =>
                      navigate(`/courses/${course?.slug}`, { state: course })
                    }
                  >
                    {t('details')}
                  </span>
                  <span
                    className='register text-white rounded-3 w-100'
                    onClick={() =>
                      navigate(`/register/${course?.slug}`, {
                        state: {
                          slug: course?.slug,
                          title_en: course?.title_en,
                          title_ar: course?.title_ar,
                          type: 'course',
                        },
                      })
                    }
                  >
                    {t('register-now')}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
