import $ from 'jquery';

export default function closeNavbarMenuMobile() {
  // close menu after click any item as navbar mobile
  $('.navbar .navbar-collapse .navbar-nav .nav-link:not(.dropdown-toggle)').on(
    'click',
    function () {
      // close menu mobile after click item
      $('.navbar-collapse').removeClass('show');
      $('.navbar-toggler').toggleClass('collapsed');
      $(this).parent().addClass('active').siblings().removeClass('active');
    }
  );
}
