import * as Yup from 'yup';
import $ from 'jquery';
import PhoneInput from 'react-phone-number-input';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { sendData } from '../../api/ApiData';
import 'react-phone-number-input/style.css';

export default function ContactUs({ t }) {
  const initialValues = {
    // initial Values input felids
    fullName: '',
    email: '',
    phone: '',
    message: '',
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    fullName: Yup.string()
      .required(t('name-required'))
      .min(3, t('name-less-three-characters'))
      .max(75, t('name-must-than-twenty-characters')),
    email: Yup.string()
      .required(t('email-required'))
      .min(6, t('email-less-three-characters'))
      .max(75, t('email-must-than-twenty-characters'))
      .email(t('invalid-email-format')),
    phone: Yup.string()
      .required(t('phone-required'))
      .min(13, t('phone-less-thirteen-digit'))
      .max(13, t('phone-must-than-thirteen-digit')),
    message: Yup.string()
      .required(t('message-required'))
      .min(3, t('message-less-three-characters'))
      .max(500, t('message-must-than-five-hundred-characters')),
  });
  const onSubmit = (values, { resetForm }) => {
    $('#page-loader').fadeIn();
    const data = {
      name: values.fullName,
      email: values.email,
      phone: values.phone,
      message: values.message,
    };
    sendData(`contact`, data)
      .then((res) => {
        if (res.status === 201) {
          $('#page-loader').fadeOut(500);
          toast.success(t('success-message-contact'));
          resetForm({ values: '' });
        }
      })
      .catch((err) => {
        toast.error(t('error-message'));
        $('#page-loader').fadeOut(500);
      });
  };
  return (
    <div className='contact-us py-5' id='contact-us'>
      <div className='container'>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) =>
            formik.values && (
              <Form className='form rounded-2 p-5 px-3'>
                <h2
                  className='section-title fw-bold text-md-center text-white'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  {t('get-in-touch')}
                </h2>
                <div className='row mt-md-5 mt-4'>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div
                        className='col-md-12'
                        data-aos='fade-up'
                        data-aos-delay='150'
                      >
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='text'
                            id='fullName'
                            name='fullName'
                            placeholder={t('full-name')}
                            onChange={formik.handleChange}
                            value={formik.values.fullName}
                          />
                          {formik.touched.fullName && formik.errors.fullName ? (
                            <div className='text-error'>
                              {formik.errors.fullName}
                            </div>
                          ) : null}{' '}
                        </div>
                      </div>
                      <div
                        className='col-md-12'
                        data-aos='fade-up'
                        data-aos-delay='200'
                      >
                        <div className='form-group'>
                          <PhoneInput
                            type='tel'
                            className='form-control'
                            inputMode='tel'
                            international
                            defaultCountry='SD'
                            required
                            initialValueFormat='national'
                            countryCallingCodeEditable={false}
                            {...formik.getFieldProps('phone')}
                            onChange={(e) => formik.setFieldValue('phone', e)}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className='text-error'>
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className='col-md-12'
                        data-aos='fade-up'
                        data-aos-delay='250'
                      >
                        <div className='form-group'>
                          <input
                            className='form-control'
                            name='email'
                            inputMode='email'
                            placeholder={t('email-address')}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className='text-error'>
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='col-md-6'
                    data-aos='fade-up'
                    data-aos-delay='200'
                  >
                    <div className='form-group'>
                      <textarea
                        className='form-control'
                        rows='7'
                        name='message'
                        placeholder={t('message')}
                        onChange={formik.handleChange}
                        value={formik.values.message}
                      ></textarea>
                      {formik.touched.message && formik.errors.message ? (
                        <div className='text-error'>
                          {formik.errors.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className='col-md-12'
                    data-aos='fade-up'
                    data-aos-delay='300'
                  >
                    <div className='form-group'>
                      <input
                        className='rounded-2'
                        type='submit'
                        value={t('send')}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
}
