import { useEffect } from 'react';

export default function LoadingOverlay() {
  useEffect(() => {
    // 👇️ hide loading after page is load
    setTimeout(() => {
      const loading = document.querySelector('#page-loader');
      loading.style.display = 'none';
    }, 1000);
  }, []);
  return (
    <div id='page-loader'>
      <div className='loader-img'>
        <img src='../../assets/images/img-logo.png' alt='Images Logo' />
      </div>
    </div>
  );
}

// position: absolute;
//     content: "";
//     padding: 0.5rem;
//     height: 50px;
//     line-height: 40px;
//     width: 50px;
//     border-radius: 50%;
//     background-position: center;
//     top: 55%;
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-color: var(--colorWhite);
//     background-image: url(http://localhost:3000/static/media/img-logo.8073b71….png);
//     left: 55%;
//     transform: translate(-50%, -50%);
