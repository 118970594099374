import $ from 'jquery';

export default function handleHideAndShowBtnScrollTop() {
  let scrollButton = $('#scroll-top');
  // list item s navbar click item to move section
  $('.navbar .navbar-collapse .navbar-nav .nav-item .nav-link').on(
    'click',
    function () {
      $('html,body').scrollTop(
        $('#' + $(this).data('value')).offset().top - 60
      );
      $(this).parent().addClass('active').siblings().removeClass('active');
      return false;
    }
  );
  // -------------------------------------------------------------------------
  // calculate scroll top and show or hide btn scroll to top
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      scrollButton.show();
    } else {
      scrollButton.hide();
    }
  });
}
