import { useNavigate, useParams } from 'react-router';
import useFetchData from '../../api/ApiData';
import Cover from '../../components/cover/Cover';
import LoadingSection from '../../helpers/loading-section/LoadingSection';

export default function DetailsCourse({ t, lang }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const {
    data: course,
    isError,
    isLoading,
    error,
  } = useFetchData(`course/${slug}?type=course`, 'course-details');
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <>
      <Cover />
      <div className='details-course py-5'>
        <div className='container pt-4'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='image'>
                <img
                  src={course?.data?.data?.image}
                  alt={
                    lang === 'en'
                      ? course?.data?.data?.title_en
                      : course?.data?.data?.title_ar
                  }
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='info'>
                <h3
                  className='title fw-bold mt-3 mt-md-0'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  {lang === 'en'
                    ? course?.data?.data?.title_en
                    : course?.data?.data?.title_ar}
                </h3>
                <p className='desc' data-aos='fade-up' data-aos-delay='150'>
                  {lang === 'en'
                    ? course?.data?.data?.description_en
                    : course?.data?.data?.description_ar}
                </p>
                <span
                  className='b-primary text-white rounded-3 text-decoration-none'
                  onClick={() =>
                    navigate(`/register/${course?.data?.data?.slug}`, {
                      state: {
                        slug: course?.data?.data?.slug,
                        title_en: course?.data?.data?.title_en,
                        title_ar: course?.data?.data?.title_ar,
                        type: 'course',
                      },
                    })
                  }
                >
                  {t('register-now')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
