import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';
import Book from './Book';

export default function BooksFiles({ t, lang }) {
  const {
    data: books,
    isError,
    isLoading,
    error,
  } = useFetchData('downloadables', 'downloadables');
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <div className='books-files text-md-center pt-4 pb-5'>
      <div className='container'>
        <h2
          className='section-title fw-bolder'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {t('books-files')}
        </h2>
        <div className='row mt-md-5 mt-4'>
          {books?.data?.data?.map((book) => (
            <Book book={book} t={t} lang={lang} />
          ))}
        </div>
      </div>
    </div>
  );
}
