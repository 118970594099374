import { useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import useFetchData from '../../api/ApiData';
import Cover from '../../components/cover/Cover';
import LoadingSection from '../../helpers/loading-section/LoadingSection';

export default function DetailsVirtual({ t, lang }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const {
    data: course,
    isError,
    isLoading,
    error,
  } = useFetchData(`course/${slug}?type=virtual`, 'virtual-details');
  const [videoUrl, setVideoUrl] = useState(course?.data?.data?.video);
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <>
      <Cover />
      <div className='details-course-virtual py-5'>
        <div className='container'>
          <div className='row gap-md-5'>
            <div className='col-md-6'>
              <div className='info'>
                <h2
                  className='title fw-bolder text-white'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  {lang === 'en'
                    ? course?.data?.data?.title_en
                    : course?.data?.data?.title_ar}
                </h2>
                <div className='video rounded-1'>
                  <iframe
                    width='100%'
                    height='240'
                    src={videoUrl}
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                </div>
                <div className='mt-3 text-center'>
                  <button
                    className='b-primary text-white rounded-3 mx-3'
                    onClick={() => setVideoUrl(course?.data?.data?.intro)}
                  >
                    {t('intro-video')}
                  </button>
                  <button
                    className='b-primary text-white rounded-3 mx-3'
                    onClick={() => setVideoUrl(course?.data?.data?.video)}
                  >
                    {t('first-video')}
                  </button>
                </div>
                <h3
                  className='sub-title fw-bold mt-4'
                  data-aos='fade-up'
                  data-aos-delay='150'
                >
                  {t('about-course')}
                </h3>
                <p className='desc' data-aos='fade-up' data-aos-delay='200'>
                  {lang === 'en'
                    ? course?.data?.data?.description_en
                    : course?.data?.data?.description_ar}
                </p>
                <span
                  className='d-none d-md-inline-block b-primary text-white rounded-3'
                  onClick={() =>
                    navigate(`/register/${course?.data?.data?.slug}`, {
                      state: {
                        slug: course?.data?.data?.slug,
                        title_en: course?.data?.data?.title_en,
                        title_ar: course?.data?.data?.title_ar,
                        type: 'virtual',
                      },
                    })
                  }
                >
                  {t('register-now')}
                </span>
              </div>
            </div>
            <div className='col-md-5'>
              <div className='content'>
                <h3 className='title fw-bold'>{t('course-content')}</h3>
                <div
                  className='count-content d-flex gap-2 mb-5'
                  data-aos='fade-up'
                  data-aos-delay='200'
                  dangerouslySetInnerHTML={{
                    __html: course?.data?.data?.video_content,
                  }}
                ></div>
              </div>
              <span
                className='d-md-none b-primary text-white rounded-3 text-decoration-none'
                onClick={() =>
                  navigate(`/register/${course?.data?.data?.slug}`, {
                    state: {
                      slug: course?.data?.data?.slug,
                      title_en: course?.data?.data?.title_en,
                      title_ar: course?.data?.data?.title_ar,
                      type: 'virtual',
                    },
                  })
                }
              >
                {t('register-now')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
