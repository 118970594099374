import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';
import Principles from './Principles';

export default function AboutCenter({ t, lang }) {
  const {
    data: about,
    isError,
    isLoading,
    error,
  } = useFetchData('about-us', 'about-us');

  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <div className='about-us py-5'>
      <div className='container'>
        <div className='row px-4 px-md-1'>
          <div className='col-md-12 order-first'>
            <h3
              className='title fw-bolder text-uppercase'
              data-aos='fade-up'
              data-aos-delay='100'
            >
              {t('about-network-plus')}
            </h3>
            <p className='desc shape' data-aos='fade-up' data-aos-delay='150'>
              {lang === 'en'
                ? about?.data?.data?.about_us_en
                : about?.data?.data?.about_us_شق}
            </p>
          </div>
          <Principles lang={lang} />
        </div>
      </div>
    </div>
  );
}
