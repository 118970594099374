import { useQuery } from 'react-query';
import ApiConfig from './ApiConfig';

// Fetch data
export default function useFetchData(url, name) {
  return useQuery(name, () => ApiConfig.get(url));
}
// ----------------------------------------------------------------------------->
// send data
export const sendData = async (url, request) => {
  const response = await ApiConfig.post(url, request);
  return response;
};
