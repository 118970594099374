import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';

export default function Principles({ lang }) {
  const {
    data: principles,
    isError,
    isLoading,
    error,
  } = useFetchData('principles', 'principles');
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <>
      <div className='col-md-9 py-md-5 order-3 order-md-2'>
        <h3
          className='title fw-bolder text-uppercase'
          data-aos='fade-up'
          data-aos-delay='150'
        >
          {lang === 'en'
            ? principles?.data?.data[0]?.title_en
            : principles?.data?.data[0]?.title_ar}
        </h3>
        <p className='desc' data-aos='fade-up' data-aos-delay='200'>
          {lang === 'en'
            ? principles?.data?.data[0]?.description_en
            : principles?.data?.data[0]?.description_ar}
        </p>
      </div>
      <div className='col-md-3 py-md-5 py-3 order-2 order-md-3'>
        <div className='image' data-aos='fade-up' data-aos-delay='150'>
          <img
            src={principles?.data?.data[0]?.images}
            alt={
              lang === 'en'
                ? principles?.data?.data[0]?.title_en
                : principles?.data?.data[0]?.title_ar
            }
          />
        </div>
      </div>
      <div className='col-md-3 order-4'>
        <div className='image' data-aos='fade-up' data-aos-delay='150'>
          <img
            src={principles?.data?.data[1]?.images}
            alt={
              lang === 'en'
                ? principles?.data?.data[1]?.title_en
                : principles?.data?.data[1]?.title_ar
            }
          />
        </div>
      </div>
      <div className='col-md-9 order-5'>
        <h3
          className='title fw-bolder text-uppercase'
          data-aos='fade-up'
          data-aos-delay='150'
        >
          {lang === 'en'
            ? principles?.data?.data[1]?.title_en
            : principles?.data?.data[1]?.title_ar}
        </h3>
        <p className='desc' data-aos='fade-up' data-aos-delay='200'>
          {lang === 'en'
            ? principles?.data?.data[1]?.description_en
            : principles?.data?.data[1]?.description_ar}
        </p>
      </div>
    </>
  );
}
