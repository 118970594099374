import cookie from 'react-cookies';

export default function changeLanguage(i18n, navigate) {
  // change lang as select user click btn lang
  navigate(0); // reload page after change language
  if (i18n.language === 'en') {
    cookie.save('lang', 'ar');
  } else if (i18n.language === 'ar') {
    cookie.save('lang', 'en');
  }
}
