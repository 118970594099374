import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

export default function Hero({ t, lang }) {
  const { data: hero } = useFetchData('hero', 'hero');
  const {
    data: slides,
    isError,
    isLoading,
    error,
  } = useFetchData('slides', 'slides');
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <main className='hero'>
      {slides && (
        <Swiper
          className='single-swiper h-100'
          spaceBetween={30}
          centeredSlides={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
        >
          {slides?.data?.data?.map((image, index) => (
            <SwiperSlide key={index}>
              <div className='info'>
                <h2 className='title fw-bolder text-white text-uppercase'>
                  {t('network-plus')}
                </h2>
                <p className='desc text-white'>
                  {lang === 'en'
                    ? hero?.data?.data?.hero_en
                    : hero?.data?.data?.hero_ar}
                </p>
              </div>
              <img src={image} alt={`img-slider ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </main>
  );
}
