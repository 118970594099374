import cookie from 'react-cookies';
import Aos from 'aos';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import DataCenter from './pages/data-center/DataCenter';
import AboutCenter from './pages/about/AboutCenter';
import BooksFiles from './pages/book-file/BooksFiles';
import Footer from './components/footer/Footer';
import Blogs from './pages/blog/Blogs';
import DetailsBlog from './pages/details-blog/DetailsBlog';
import BtnWhatsapp from './components/btn-whatsapp/BtnWhatsapp';
import Register from './pages/register/Register';
import DetailsCourse from './pages/details-course/DetailsCourse';
import Courses from './pages/course/Courses';
import DetailsVirtual from './pages/details-course/DetailsVirtual';
import LoadingOverlay from './helpers/loading-overlay/LoadingOverlay';
import { ToastContainer } from 'react-toastify';
import Virtual from './pages/virtual/virtual';

function App() {
  const { t } = useTranslation();
  const [lang, setLang] = useState();
  const [phone, setPhone] = useState();
  // ----------------------------------------------------------------------------->
  useEffect(() => {
    setLang(
      cookie.load('lang') ? cookie.load('lang') : cookie.save('lang', 'en') // check type language
    );
  }, []);
  // ----------------------------------------------------------------------------->
  useEffect(() => {
    Aos.init(); // initial aos animation library
  }, []);
  return (
    <BrowserRouter>
    <ToastContainer/>
      <Navbar lang={lang}/>
      <Routes>
        <Route path='/' element={<Home t={t} lang={lang} />} />
        <Route path='/data-center-rental' element={<DataCenter t={t} lang={lang} />} />
        <Route path='/about-the-center' element={<AboutCenter t={t} lang={lang} />} />
        <Route path='/books' element={<BooksFiles t={t} lang={lang} />} />
        <Route path='/blogs' element={<Blogs t={t} lang={lang} />} />
        <Route path='/blogs/:slug' element={<DetailsBlog t={t} lang={lang} />} />
        <Route path='/register/:slug' element={<Register t={t} lang={lang} />} />
        <Route path='/courses' element={<Courses t={t} lang={lang} />} />
        <Route path='/courses/virtual' element={<Virtual t={t} lang={lang} />} />
        <Route path='/courses/:slug' element={<DetailsCourse t={t} lang={lang} />} />
        <Route path='/courses/virtual/:slug' element={<DetailsVirtual t={t} lang={lang} />} />
      </Routes>
      <Footer t={t} lang={lang} setPhone={setPhone}/>
      <BtnWhatsapp phone={phone}/>
      <LoadingOverlay/>
    </BrowserRouter>
  );
}

export default App;
