import { useNavigate } from 'react-router';

export default function Blog({ blog, lang }) {
  const navigate = useNavigate();

  return (
    <div
      key={blog?.slug}
      onClick={() => navigate(`/blogs/${blog?.slug}`, { state: blog })}
      className='col-lg-4 col-md-6 h-100'
      data-aos='fade-right'
      data-aos-delay='150'
    >
      <div className='blog'>
        <div className='image'>
          <img
            src={blog?.image}
            alt={lang === 'en' ? blog?.title_en : blog?.title_ar}
          />
        </div>
        <div className='info'>
          <h3 className='title' data-aos='fade-up' data-aos-delay='150'>
            {lang === 'en' ? blog?.title_en : blog?.title_ar}
          </h3>
        </div>
      </div>
    </div>
  );
}
