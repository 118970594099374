import useFetchData from '../../api/ApiData';
import LoadingSection from '../../helpers/loading-section/LoadingSection';

export default function DataCenter({ t, lang }) {
  const { data, isError, isLoading, error } = useFetchData(
    'data-center',
    'data-center'
  );
  // check is error
  if (isError) return <div className='text-error'>{error}</div>;
  // check is loading
  if (isLoading) return <LoadingSection />;
  return (
    <div className='data-center-rental pt-4 pb-5'>
      <div className='container'>
        <h2 className='title fw-bolder' data-aos='fade-up' data-aos-delay='100'>
          {t('data-center-rental')}
        </h2>
        <p className='desc' data-aos='fade-up' data-aos-delay='150'>
          {lang === 'en'
            ? data?.data?.data?.data_center_description_en
            : data?.data?.data?.data_center_description_ar}
        </p>
        <div className='image' data-aos='fade-up' data-aos-delay='200'>
          <img src={data?.data?.data?.data_center_image} alt='img-1' />
        </div>
      </div>
    </div>
  );
}
